import * as React from "react"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useState } from "react"
import "../components/1stlogin.css"
import AxiosInstance from "../services/axiosInstance"
import { useEffect } from "react"
import { navigate } from "gatsby"

const SignupStep = ({ loginUserDetail }) => {
  const [currrentStep, setCurrrentStep] = useState(1),
    [companyUsername, setCompanyUsername] = useState(loginUserDetail.username),
    [personalUsername, setPersonalUsername] = useState(loginUserDetail.personalUsername),
    [isShowPersonalContainer, setIsShowPersonalContainer] = useState(false),
    [aboutUsId, setAboutUsId] = useState(null),
    [aboutUsValue, setAboutUsValue] = useState("")

  const handleSignupStep = step => {
    setCurrrentStep(step)
  }

  const updateUserDetail = async () => {
    if (!companyUsername || !personalUsername) {
      alert("Please fill out the box.")
      return false
    }

    await AxiosInstance.post(`/user/update-user-detail/${loginUserDetail.userId}`, {
      username: companyUsername,
      personalUsername: personalUsername,
    })
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          localStorage.setItem("userName", companyUsername)
          handleSignupStep(2)
        }
      })
      .catch(error => {
        console.log("error", error)
        if (error.response.status === 400) {
          alert(error.response.data.message)
          return false
        }
      })
  }

  const getGeneralSettingData = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map(data => {
        if (data.type === "AboutUs") {
          setAboutUsId(data.id)
          setAboutUsValue(data.value)
        }
      })
    })
  }

  const updateAboutUs = () => {
    if (!aboutUsValue) {
      alert("Please fill out the box.")
      return false
    }

    let url = "/settings/general-setting/add"
    if (aboutUsId) {
      url = `/settings/general-setting/edit/${aboutUsId}`
    }

    AxiosInstance.post(url, {
      type: "AboutUs",
      value: aboutUsValue,
      privacy: "public",
    })
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          handleSignupStep(3)
        }
      })
      .catch(function (error) {})
  }

  const backgroundImageStyle = {
    background: `url(${process.env.GATSBY_WRANGLER_URL}StaticImages/homeblur.png) no-repeat center / cover`,
  }

  const handleCloseSignupStep = () => {
    Object.assign(document.body.style, {
      backgroundImage: "none",
    })

    navigate("/drive")
  }

  useEffect(() => {
    getGeneralSettingData()
    Object.assign(document.body.style, backgroundImageStyle)

    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.style.opacity = 0;
    }
  }, [])

  return (
    <Modal show={true} id="signupModal" dialogClassName={`modal-dialog-centered modal-lg`}>
      <Modal.Body>
        <input type="hidden" name="signupStepCnt" id="signupStepCnt" defaultValue={1} />
        <div className={`signupStep1${currrentStep !== 1 ? " d-none" : ""}`}>
          <div className="text-center pt-3 mb-4">
            <h2 className="fw-bold">Welcome to SwiftCloud!</h2>
            <p className="fs-5">We're excited to join your team.</p>
            <p>Please pick a snazzy new username for both your company, and yourself.</p>
          </div>
          <div className="max-350">
            <div className="input-group mb-3">
              <span className="input-group-symbol">
                <i className="bi bi-building-fill" />
              </span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Most people match their domain, or other social handles.</Tooltip>}
              >
                <span className="input-group-text">@</span>
              </OverlayTrigger>
              <input
                type="text"
                className="form-control company_username"
                placeholder="Company Username"
                defaultValue={companyUsername}
                onChange={e => {
                  setIsShowPersonalContainer(true)
                  setCompanyUsername(e.target.value)
                }}
              />
            </div>
            <div className="input-group mb-3 position-relative">
              <span className="input-group-symbol">
                <i className="bi bi-person-fill" />
              </span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    If you choose to add a team, others can mention you with this. Small teams are often just
                    first name.
                  </Tooltip>
                }
              >
                <span className="input-group-text">@</span>
              </OverlayTrigger>
              <input
                type="text"
                className="form-control personal_username"
                placeholder="Personal Username"
                defaultValue={personalUsername}
                onChange={e => {
                  setIsShowPersonalContainer(true)
                  setPersonalUsername(e.target.value)
                }}
              />
              <span
                className="input-group-text personal_username_container"
                style={{ display: isShowPersonalContainer ? "inline" : "none" }}
              >
                .{companyUsername}
              </span>
            </div>
          </div>
          <div className="signupStepFooter">
            <div style={{ width: 100 }}>&nbsp;</div>
            <div
              className="progress"
              role="progressbar"
              aria-valuenow={60}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ height: 10 }}
            >
              <div className="progress-bar bg-success" style={{ width: "60%" }} />
            </div>
            <button className="btn btn-primary signupStep1Opt" onClick={updateUserDetail}>
              Next <i className="bi bi-arrow-right" />
            </button>
          </div>
        </div>
        <div className={`signupStep2${currrentStep !== 2 ? " d-none" : ""}`}>
          <div className="text-center pt-3 mb-4">
            <h2 className="fw-bold">About You &amp; Your Team</h2>
          </div>
          <div className="signupStep2Body">
            <div className="signupStep2Opt0 mb-5">
              <label className="form-label">How did you hear about us?</label>
              <input
                type="text"
                className="form-control how_did_you_hear"
                defaultValue={aboutUsValue}
                onChange={e => setAboutUsValue(e.target.value)}
              />
            </div>
            <div className="signupStep2Opt1 mb-5 d-none">
              <label className="form-label">
                Have a company website? <span className="color-red">*</span>
              </label>
              <input
                type="url"
                name="company_website"
                className="form-control"
                defaultValue="https://"
                placeholder="https://"
              />
            </div>
            <div className="signupStep2Opt2 mb-5 d-none">
              <label className="form-label">What's your company size?</label>
              <div className="dropdown">
                <button
                  className="btn btn-default dropdown-toggle w-100"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  data-bs-auto-close="true"
                >
                  Select one of the following...
                </button>
                <ul className="dropdown-menu show">
                  <li>
                    <a className="dropdown-item" href="#">
                      Just me
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      2-5
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6-12
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      13-49
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      50-200
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      200+
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="signupStep2Opt3 mb-5 d-none">
              <label className="form-label">What's your main role there?</label>
              <div className="dropdown">
                <button
                  className="btn btn-default dropdown-toggle w-100"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  data-bs-auto-close="true"
                >
                  Select one of the following...
                </button>
                <ul className="dropdown-menu show">
                  <li>
                    <a className="dropdown-item" href="#">
                      Owner or Manager
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Operations &amp; Admin
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Sales &amp; Marketing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Finance
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      HR
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Other
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="signupStepFooter">
            <button className="btn btn-default btnBack" onClick={() => handleSignupStep(1)}>
              <i className="bi bi-arrow-left" /> Back
            </button>
            <div
              className="progress"
              role="progressbar"
              aria-valuenow={60}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ height: 10 }}
            >
              <div className="progress-bar bg-success" style={{ width: "90%" }} />
            </div>
            <button className="btn btn-primary btnNext" onClick={updateAboutUs}>
              Next <i className="bi bi-arrow-right" />
            </button>
          </div>
        </div>
        <div className={`signupStep3${currrentStep !== 3 ? " d-none" : ""}`}>
          <a href="#" className="btnGotoDrive" onClick={handleCloseSignupStep}>
            <i className="bi bi-x-lg" />
          </a>
          <div className="signupStep3Body">
            <div className="mb-3">
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/4fXIoWptIvc?si=pGOYn9NfEW8hTJ_e"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen=""
                />
              </div>
            </div>
            <div className="mt-3 text-center">
              <a href="#" className="btn btn-primary btn-lg" onClick={handleCloseSignupStep}>
                Close
              </a>
            </div>
          </div>
          {/*                            <div class="signupStepFooter">
                        <button class="btn btn-default btnBack"><i class="bi bi-arrow-left"></i> Back</button>
                        <div class="progress" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style="height: 10px">
                            <div class="progress-bar bg-success" style="width: 30%"></div>
                        </div>
                    </div>*/}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignupStep
